<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout column white wrap ma-2>
      <!-- USERS -->
      <v-flex px-4>
        <h2>
          Utilisateurs
        </h2>
      </v-flex>
      <v-flex>
        <v-flex right py-0>
          <v-btn
            v-if="isAdmin"
            :disabled="!isAdmin"
            center
            class="success"
            to="/user/create"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            <v-flex class="ml-2 pa-0">
              Ajouter un utilisateur
            </v-flex>
          </v-btn>
        </v-flex>
      </v-flex>
      <material-DataTableUser
        v-if="users"
        :users="users"
        can-search
        @editRow="editUser($event)"
        @deleteRow="
          isDeletingUser = true;
          deletingId = $event.id;
          isActive = $event.is_active;
        "
      />

      <!-- DELETE DIALOG BOX -->
      <!-- deleteUser($event) -->
      <ui-DialogBox
        :title="
          isActive >= 1 ? 'Désactiver un utilisateur' : 'Activer un utilisateur'
        "
        :msg="
          isActive >= 1
            ? 'Voulez-vous vraiment désactiver cet utilisateur ?'
            : 'Voulez-vous vraiment activer cet utilisateur ?'
        "
        :show="isDeletingUser"
        icon-title="mdi-close-outline"
        cancel-icon="close-circle-outline"
        ok-icon="check-circle-outline"
        @cancel="isDeletingUser = false"
        @ok="
          isDeletingUser = false;
          deleteUser(deletingId);
        "
      />
      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'UserList',

  mixins: [SnackBarMixin],

  data: () => ({
    isDeletingUser: false,
    deletingId: 0,
    isActive: 0,
  }),

  computed: {
    ...mapGetters('user', ['getUsers']),

    users() {
      return this.getUsers();
    },
  },

  mounted() {
    // get the user informations to display in cards
    this.getDataUsers();
  },

  methods: {
    ...mapActions('user', ['get_users', 'delete_user_by_id']),
    ...mapMutations('app', ['showLoader']),

    getDataUsers() {
      this.showLoader();
      this.get_users()
        .then((response) => {
          console.log('Users OK', response);
        })
        .catch((error) => {
          console.log('Erreur', error);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Obtention des Utilisateurs impossible : ' + error.message,
          });
        })
        .finally(() => {
          this.showLoader();
        });
    },

    editUser(row) {
      console.log('editUser', row.id);
      this.$router.push({ path: `/user/${row.id}/edit` });
    },

    deleteUser(id) {
      console.log('deleteUser', id);
      this.showLoader();
      this.delete_user_by_id(id)
        .then((response) => {
          console.log('Users OK', response);
        })
        .catch((error) => {
          console.log('Erreur', error);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Suppression du utilisateur impossible : ' + error.message,
          });
        })
        .finally(() => {
          this.showLoader();
        });
    },
  },
};
</script>
